<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col" v-html="$t('tablasNutricionales.cada100Gpolvo_br')"></th>
        <th scope="col">{{ $t('tablasNutricionales.cada100ml' , {pv:"17,1"} ) }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>437</td>
        <td>75</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Kj</td>
        <td>1828</td>
        <td>313</td>
      </tr>
      <tr>
        <td>{{ $t("tablaNutricional.carbohidratos")}}</td>
        <td>g</td>
        <td>67</td>
        <td>11</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>13</td>
        <td>2,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>13</td>
        <td>2,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>g</td>
        <td>11</td>
        <td>1,9</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>g</td>
        <td>0,61</td>
        <td>0,10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>g</td>
        <td>1,1</td>
        <td>0,18</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linoleico_la') }}</td>
        <td>g</td>
        <td>0,21</td>
        <td>0,04</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linolenico_ala') }}</td>
        <td>g</td>
        <td>0,60</td>
        <td>0,10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.ara') }}</td>
        <td>mg</td>
        <td>66</td>
        <td>11</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.dha') }}</td>
        <td>mg</td>
        <td>66</td>
        <td>11</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>mg</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>213</td>
        <td>36</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>mg</td>
        <td>428</td>
        <td>73</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>mg</td>
        <td>339</td>
        <td>58</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>mg</td>
        <td>353</td>
        <td>60</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>mg</td>
        <td>294</td>
        <td>50</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>mg</td>
        <td>43</td>
        <td>7,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>mg</td>
        <td>7,4</td>
        <td>1,3</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>mg</td>
        <td>2,2</td>
        <td>0,38</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>mcg</td>
        <td>263</td>
        <td>45</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>mg</td>
        <td>0,42</td>
        <td>0,07</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fluor') }}</td>
        <td>mg</td>
        <td>0,26</td>
        <td>0,05</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>mcg</td>
        <td>11</td>
        <td>1,8</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>mcg</td>
        <td>13</td>
        <td>2,2</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>mcg</td>
        <td>11</td>
        <td>1,8</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>mcg</td>
        <td>80</td>
        <td>14</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="4">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>mcg RE</td>
        <td>277</td>
        <td>47</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>mcg</td>
        <td>8,8</td>
        <td>1,5</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>mg alfa TE</td>
        <td>9,7</td>
        <td>1,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>mcg</td>
        <td>28</td>
        <td>4,7</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>mg</td>
        <td>0,37</td>
        <td>0,06</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>mg</td>
        <td>0,65</td>
        <td>0,11</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>mg</td>
        <td>2,8</td>
        <td>0,47</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>mg</td>
        <td>2,4</td>
        <td>0,41</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>mg</td>
        <td>0,33</td>
        <td>0,06</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>mcg</td>
        <td>60</td>
        <td>10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>mcg</td>
        <td>0,83</td>
        <td>0,14</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>mcg</td>
        <td>14</td>
        <td>2,4</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>mg</td>
        <td>55</td>
        <td>10</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>mg</td>
        <td>104</td>
        <td>18</td>
      </tr>
      <tr>
        <td colspan="4">{{ $t('tablasNutricionales.osmolaridad', {osmolaridad: 190, solutos:193}) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableLipidgen",
};
</script>
